import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import SwitchLang from './components/SwitchLang.vue'
import HeaderBanner from '@/components/HeaderBanner.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyload from 'vue-lazyload'  //引入这个懒加载插件
import Spinner from 'vue-simple-spinner'


import zh from '@/assets/lang/zh';
import en from '@/assets/lang/en'; // 创建vue-i18n实例i18n

Vue.use(VueI18n)
Vue.use(ElementUI);
Vue.use(VueLazyload);
Vue.use(Spinner);
//测试地址
// Vue.prototype.BASE_URL="https://meeting-test.hytun.net" 
//正式地址
Vue.prototype.BASE_URL="https://meeting.hytun.net" 

Vue.prototype.$toast = function toast(msg,duration){
  duration = isNaN(duration) ? 3000 : duration;
  var m = document.createElement('div');
  m.innerHTML = msg;
  m.style.cssText = `
    max-width:70%;
    min-width: 120px;
    padding:4px 14px;
    color: rgb(255, 255, 255);
    text-align: center;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    background: rgba(0, 0, 0,.7);
    font-size: 14px;`;
  document.body.appendChild(m);
  setTimeout(function() {
      var d = 0.5;
      m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';
      m.style.opacity = '0';
      setTimeout(function() { document.body.removeChild(m) }, d * 1000);
  }, duration);
}

const i18n = new VueI18n({
  locale: localStorage.getItem('boao_lang') || 'zh', 
  messages: {
    zh,
    en
  }
});


Vue.config.productionTip = false

Vue.component('SwitchLang', SwitchLang)
Vue.component('HeaderBanner', HeaderBanner)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default i18n