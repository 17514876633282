export default {
  verificationCode: '播放码',
  liveNow: '进入直播',
  needHelp: '登录有疑问？',
  contactSupport: '点击解决',
  pleaseEnterValidCode: '播放码无效，请重新输入',
  forumReviews: '分论坛精彩回顾',
  view: '关注',
  scheduled: '未开始',
  live: '直播中',
  allForums: '全部分论坛',
  speaker: '主讲人',
  topic: '议题',
  liveCenter: '直播中心',
  photos: '图片',
  videos: '视频',
  fullImage: '查看原图',
  completed:'已完成',
  pressToDownload: '长按下载',
  noMore: '精彩相片正在搬运中，请稍后刷新',
  hi: '尊敬的论坛大会嘉宾，您好！',
  playTimes: '您的播放码已达最多可观看的论坛次数',
  error: '系统错误',
  confirm: '确定',
  needLogin: '请重新验证播放码',
  viewImage: '查看图片',
  welcome: '欢迎！',
  title: '线上直播'
}