import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import LiveLogin from '@/views/live/login.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'LiveLogin',
  //   component: LiveLogin
  // },
  // {
  //   path: '/liveLogin',
  //   name: 'LiveLogin',
  //   component: LiveLogin
  // },
  // {
  //   path: '/liveList', //path: '/',
  //   name: 'LiveList',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "LiveList" */ '../views/live/list.vue'),
  //   meta: {
  //     keepAlive: true
  //   }
  // },
  // {
  //   path: '/liveDetail',
  //   name: 'LiveDetail',
  //   component: () => import(/* webpackChunkName: "LiveDetail" */ '../views/live/detail.vue')
  // },
  // {
  //   path: '/albumPreview',
  //   name: 'AlbumPreview',
  //   component: () => import(/* webpackChunkName: "AlbumPreview" */ '../views/album/list.vue'),
  //   meta: {
  //     keepAlive: true
  //   }
  // },
  // {
  //   path: '/albumPlay',
  //   name: 'albumPlay',
  //   component: () => import(/* webpackChunkName: "AlbumList" */ '../views/album/preview.vue')
  // },
  // {
  //   path: '/albumLoad',
  //   name: 'AlbumLoad',
  //   component: () => import(/* webpackChunkName: "AlbumLoad" */ '../views/album/home.vue')
  // },
  // {
  //   path: '/', //albumForum
  //   name: 'AlbumForum',
  //   component: () => import(/* webpackChunkName: "AlbumLoad" */ '../views/album/forum.vue')
  // },
  {
    path: '/', 
    name: 'DolphinList',
    component: () => import(/* webpackChunkName: "AlbumLoad" */ '../views/dolphin/list.vue'),
	// meta: { title: '账号列表' ,rank:1.5}
  },
  // {
  //   path: '/albumActivity',
  //   name: 'AlbumActivity',
  //   component: () => import(/* webpackChunkName: "AlbumLoad" */ '../views/album/activity.vue')
  // },
  {
    path: '/dolphinDetail', 
    name: 'DolphinDetail',
    component: () => import(/* webpackChunkName: "AlbumLoad" */ '../views/dolphin/detail.vue'),
	meta: { keepAlive:true }

  },
  {
    path: '/dolphinPreview', 
    name: 'DolphinPreview',
    component: () => import(/* webpackChunkName: "AlbumLoad" */ '../views/dolphin/preview.vue'),
	// meta: { title: '图片预览' ,rank:3.5}
  },
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => { 
//   console.log('to.name: ', to.name);
//   if (to.path.includes('album'))
//   {
//     document.title = 'ISTIF'
//   } else
//   { 
//     document.title = 'ISTIF Live'
//   }
//   next()
// })

export default router
