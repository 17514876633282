<template>
  <div id="app">
	<!-- <keep-alive :include="keepAlive">
		<router-view  />
	</keep-alive> -->
	<keep-alive>
	    <router-view v-if="$route.meta.keepAlive" :key="key" />
	</keep-alive>
	<router-view v-if="!$route.meta.keepAlive" :key="key" />
  </div>
</template>
<script>
	export default{
		name:"App",
		computed: {
		  key() {
			return this.$route.fullPath;
		  }
		},
		data(){
			return {
				keepAlive:[]
			}
		},
	}
</script>
<style lang="less">
 * {
    font-family: 'helvetica neue',arial,'hiragino sans gb',stheiti,'wenquanyi micro hei',\5FAE\8F6F\96C5\9ED1,\5B8B\4F53,sans-serif;
 }
  body {
    margin:0;
  }
  .login-power {
    position: relative;
    white-space: nowrap;
    font-size: 14px;
    color: #A4A4A7;
    margin-top: 40px;
    text-align: center;
    padding-bottom: 20px;
  }
  .login-icon {
    width: 70px;
  }
  #app {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
  }
  img {
    width: 100%;
  }
  .prism-big-play-btn {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
	
	@media (min-width: 768px){
		html,body,#app{
			width: 100%;
			height: 100%;
		}
		#app {
			max-width: initial;
		}
	}
</style>