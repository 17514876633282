<template>
  <div :class="from + '-switch'" @click="changeLang">
    <img loading=”lazy” class="login-switch-icon" :src="imgSrc" alt=""> {{ lang }}
  </div>
</template>
<script>
export default {
  name: 'switch-lang',
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  computed: {
    imgSrc () {
      return require(`@/assets/img/live/${this.from}switch.png`)
    },
    lang () {
      return this.$i18n.locale === 'en' ? '中' : 'EN'
    }
  },
  methods: {
    changeLang() {
      // console.log(this.$i18n.locale);
      this.$i18n.locale === 'en' ? this.$i18n.locale = 'zh' : this.$i18n.locale = 'en'
      localStorage.setItem('boao_lang', this.$i18n.locale)
    }
  }
}
</script>
<style lang="less" scoped>
  .login-icon {
    width: 70px;
  }

  .login-switch {
    position: absolute;
    top: 0;
    right: 10px;
    color: #fff;
    font-size: 13px;
    z-index: 999;
  }

  .list-switch {
    position: absolute;
    top: 0;
    right: 10px;
    color: #56718B;
    font-size: 13px;
    z-index: 999;
  }


  .login-switch-icon {
    width: 15px;
    vertical-align: middle;
    margin-right: 3px;
  }
</style>