<template>
  <div class="login-bg">
    <header-banner />
    <div class="login-code-container">
      <div class="login-tip">{{$t('verificationCode')}}</div>
      <input class="login-code login-btn" v-model="playCode" placeholder="请输入播放码" />
      <div class="login-go login-btn" @click="goList">{{$t('liveNow')}}</div>
      <div class="login-tip login-q">{{$t('needHelp')}}</div>
      <div class="login-solution login-btn" @click="showModal = true">{{$t('contactSupport')}}</div>
    </div>
    <!-- <div class="login-power">Powered by <img loading=”lazy” class="login-icon" src="@/assets/img/live/logo.png" alt=""></div> -->
    <div class="modal" v-show="showModal">
      <div class="modal-content">
        <div>观看直播收费标准：200元/场</div>
        <div>联系方式：</div>
        <div>客服：周小姐</div>
        <div>电话：+86 198 0203 9505（微信同号）</div>
        <div>在线时间：周一至周日 9:00~18:00</div>
        <div>官网 : www.bfa-istif.cn</div>
        <div>邮箱：info@bfa-istif.cn</div>
        <div>地址：</div>
        <div>①广州市海珠区新港东路1066号中洲中心国茶荟2楼</div>
        <div>②澳门特别行政区冼星海大马路105号金龙中心18楼A-C及M-N室</div>
        <div class="modal-btn" @click="showModal = false">{{$t('confirm')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/request.js'
export default {
  name: 'live-login',
  data () {
    return {
      playCode: '',
      showModal: false
    }
  },
  created () {
    sessionStorage.clear()
  },
  methods: {
    help () {},
    goList () {
      if(String(this.playCode).length === 0) {
        return
      }
      request.get(
        '/api/broadcastticket/checkticket',
        {
          params: { code: this.playCode }
        }
      ).then(({ data }) => {
        console.log('data: ', data);
        sessionStorage.setItem('live_token', data.token)
        sessionStorage.setItem('user_name', data.user_name)

        this.$router.push({
          path: '/liveList'
        })
      })
      // this.$toast('test')
    }
  }
}
</script>
<style lang="less" scoped>
  .login-bg {
    background: #E9E9F0;
    text-align: center;
    height: 100vh;
  }

  .login-btn {
    width: 60%;
    padding: 10px 20px;
    margin: 0 auto;
  }

  .login-code-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 88%;
    border-radius: 20px;
    background: #fff;
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    margin-top: -130px;
  }

  .login-tip {
    color: #999999
  }

  .login-code {
    color: #202022;
    font-size: 24px;
    margin: 20px auto;
    font-weight: bolder;
    border-radius: 54px;
    box-shadow: 0px 10px 32px 0px rgba(199,203,225,0.4); 
    border:none;
    outline: none;
    text-align: center;
    -webkit-appearance: none;
  }
  ::-webkit-input-placeholder{
    color: #eee;
    font-size: 20px;
    font-weight: normal;
  }
  .login-go {
    color: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 28px 0px rgba(110,100,223,0.36); 
    margin: 10px auto;
    background: linear-gradient(#2e2590 0%, #4338c5 100%);
  }

  .login-q {
    position: relative;
    font-size: 14px;
    margin: 60px auto 20px;
  }

  .login-q::before {
    position: absolute;
    content: '';
    width: 40px;
    height: 1px;
    left: -70%;
    top: 50%;
    background: #999999;
  }

  .login-q::after {
    position: absolute;
    content: '';
    width: 40px;
    height: 1px;
    right: -70%;
    top: 50%;
    background: #999999;
  }

  .login-solution {
    color: #3F59AD;
    border-radius: 20px;
    font-size: 14px;
    background: #efefef;
    margin: 0 auto;
  }

  .login-power {
    position: relative;
    white-space: nowrap;
    font-size: 14px;
    color: #A4A4A7;
    margin-top: 40px;
    text-align: center;
    padding-bottom: 20px;
  }

  .login-icon {
    width: 70px;
  }

  .login-switch {
    position: fixed;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 13px;
  }

  .list-switch {
    position: fixed;
    top: 10px;
    right: 10px;
    color: #56718B;
    font-size: 13px;
  }


  .login-switch-icon {
    width: 15px;
    vertical-align: middle;
    margin-right: 3px;
  }


  .modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 16px 10px 0px 10px;
    border-radius: 10px;
    width: 80%;
  }
  .modal-btn {
    border-top: 1px solid #eee;
    text-align: center;
    padding: 10px 0;
    margin-top: 10px;
  }
</style>