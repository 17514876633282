<template>
  <div>
    <img loading=”lazy” :src="bgimg" style="width: 100%;" alt="">
    <switch-lang style="margin-top: 10px" from="login"/>
  </div>
</template>
<script>
export default {
	name: 'header-banner',
	// props: {
	//   bgimg: {
	//     type: String,
	//     default: '@/assets/img/live/head.png'
	//   }
	// },
	props:{
		bgimg:{
			type: String,
			default: '@/assets/img/live/head.png'
		}
	}
}
</script>