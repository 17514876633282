import axios from 'axios'
import Vue from 'vue'

import i18n from './main'
import router from '@/router/index'

const request = axios.create({
  // baseURL: 'http://api.bfa-istif.net:8080/',
  baseURL: 'http://api.bfa-istif.net/',
  // baseURL: 'http://www.boao.com/', //本地
  timeout: 1000000000,  
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

request.interceptors.request.use(config=>{ 
  if (config.method == 'post')
  {
    if (config.url !== '/api/get_image' && config.url !== '/api/get_video') { 
      config.data = {
        ...config.data,
        token: sessionStorage.getItem('live_token') || '',
        // token: '',
      }
    }
  } else if (config.method == 'get')
  {
    if (config.url !== '/api/broadcastforum/index')
    {
      config.params = {
        token: sessionStorage.getItem('live_token') || '',
        // token: '',
        ...config.params
      }
    }
  }
 return config;
 },err=>{
   return Promise.reject(err);
 });

request.interceptors.response.use(response => {
  console.log('response: ', response);

   if (response.data.msg === 'ApiNoLogin') {
    Vue.prototype.$toast(i18n.t('needLogin'))
     router.replace({
       path: '/'
     })
     return
   }
   if (response.data.msg === 'No playable times') {
    Vue.prototype.$toast(i18n.t('playTimes'))
    router.back()
    return
   }
  
  if (!response.data.success && response.config.url === '/api/broadcastticket/checkticket') { 
    Vue.prototype.$toast(i18n.t('pleaseEnterValidCode'))
    return
  }
  return Promise.resolve(response.data)
  }, error => {
    Vue.prototype.$toast(i18n.t('error'))
    console.log('error: ', error);
  // if(error.response.status === 401){
  //对应的token过期的操作
  // }
  return Promise.reject(error)
})

export default request