export default {
  verificationCode: 'Verification Code',
  liveNow: 'Live Now!',
  needHelp: 'Need Help',
  contactSupport: 'Contact Support',
  pleaseEnterValidCode: 'Please enter a valid code',
  forumReviews: 'Forum Reviews',
  view: 'Followed',
  scheduled: 'Not started',
  live: 'Live',
  allForums: 'All Forums',
  speaker: 'Speaker',
  topic: 'Topic',
  liveCenter: 'Live Center',
  photos: 'Photos',
  videos: 'Videos',
  fullImage: 'Full image',
  completed:'completed',
  pressToDownload: 'Press to download',
  noMore: 'More photos coming soon',
  hi: 'Welcome, VIP guest!',
  playTimes: 'Your verification code exceeds viewable',
  error: 'system error',
  confirm: 'ok',
  needLogin: 'Please re-enter verification code',
  viewImage: 'View Image',
  welcome: 'Welcome!',
  title: 'Live'
}